import _ from 'lodash';
(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .service('CurrentUser', CurrentUser);

  function CurrentUser(storage, $state, EvaLogger, UserPermissionManager) {
    'ngInject';

    let loggedIn = false;
    let userId;
    let username;
    let fullname;
    let email;
    let domains;
    let roles;
    let teamsUserHasAccessTo;

    function getAssignmentNameFromDomain(domain) {
      // Null when logging out.
      if (!domains) {
        return null;
      }
      for (let i = 0; i < domains.length; i++) {
        if (domains[i].domain === domain) {
          return domains[i].name;
        }
      }
      return null;
    }

    function logIn(data) {
      if (!data.authenticated) {
        return false;
      }
      if (data.authenticated.username === undefined) {
        return false;
      }
      loggedIn = true;
      userId = data.authenticated.userId;
      username = data.authenticated.username;
      fullname = data.authenticated.givenName;
      email = data.authenticated.email;
      domains = data.authenticated.domains;
      roles = data.roles;
      updateTeamsUserHasAccessTo(domains, roles);
      UserPermissionManager.initialize(data.roles);

      EvaLogger.setUser({ id: userId, username: fullname, email});
      return true;
    }

    function logOut() {
      EvaLogger.removeUser();
      UserPermissionManager.destroy(); 

      const username_settings=JSON.parse(storage.getItem(username + '_setting'));
      // we want to keep only manualModerationQueuesSorting  decisionRules setting when user logs out
      if(username_settings){
        // remove recentSearch property and search page filters because they may contain sensitive data such as userId
        delete username_settings.recentSearch;
        delete username_settings.searchSidebarFilter;
        storage.setItem(username + '_setting', JSON.stringify(username_settings));
      }
      resetVariables();
    }

    function resetVariables() {
      loggedIn = false;
      userId = undefined;
      username = undefined;
      fullname = undefined;
      email = undefined;
      domains = undefined;
      roles = undefined;
      teamsUserHasAccessTo = undefined;
    }

    function getFullname() {
      return fullname;
    }

    function getDomains() {
      return domains;
    }

    function updateTeamsUserHasAccessTo(domains, roles) {
      teamsUserHasAccessTo = _.filter(domains, (domainData)=> {
        return _.some(roles, (roleData)=>
          (roleData.domain === domainData.domain) && (roleData.role !== 'No access'));
      });
    }

    function getTeamsUserHasAccessTo() {
      return teamsUserHasAccessTo;
    }

    function updateDomains(updatedDomains) {
      if (updatedDomains) {
        domains = updatedDomains;
        updateTeamsUserHasAccessTo(domains, roles);
      }
    }

    return {
      getFullname,
      getDomains,
      getAssignmentNameFromDomain,
      get loggedIn() {
        return loggedIn;
      },
      get username() {
        return username;
      },
      get email() {
        return email;
      },
      logIn,
      logOut,
      updateDomains,
      getTeamsUserHasAccessTo,
      get roles() {
        return roles;
      },
    };
  }
})();
