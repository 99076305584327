"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tooltip = void 0;
var react_1 = __importStar(require("react"));
var react_i18next_1 = require("react-i18next");
var number_1 = require("@utils/number");
var __1 = require("../..");
var tooltipBottomMargin = 40;
var DEFAULT_TOOLTIP_HEIGHT = 215;
var DEFAULT_TOOLTIP_WIDTH = 212;
function Tooltip(_a) {
    var isVisible = _a.isVisible, dataIndex = _a.dataIndex, highestBinBarHeight = _a.highestBinBarHeight, leftPos = _a.leftPos, tooltipContent = _a.tooltipContent;
    var _b = __read((0, react_1.useState)(0), 2), top = _b[0], setTop = _b[1];
    var _c = __read((0, react_1.useState)(0), 2), left = _c[0], setLeft = _c[1];
    var t = (0, react_i18next_1.useTranslation)().t;
    var tooltip = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (isVisible) {
            var top_1 = tooltip.current ? tooltip.current.offsetHeight : 0;
            var left_1 = tooltip.current ? tooltip.current.offsetWidth : 0;
            setTop(__1.margin.top + highestBinBarHeight - (top_1 || DEFAULT_TOOLTIP_HEIGHT) + tooltipBottomMargin);
            setLeft(leftPos - (left_1 || DEFAULT_TOOLTIP_WIDTH) / 2);
        }
    }, [isVisible, dataIndex, highestBinBarHeight, leftPos]);
    return (react_1.default.createElement("div", { className: "overview-tooltip tooltip js-tooltip ".concat(isVisible && (tooltipContent === null || tooltipContent === void 0 ? void 0 : tooltipContent.title) ? 'fadeIn' : 'fadeOut'), ref: tooltip, style: { top: top, left: left } },
        react_1.default.createElement("div", { className: "tooltip-wrapper" },
            react_1.default.createElement("h4", null, tooltipContent === null || tooltipContent === void 0 ? void 0 : tooltipContent.title),
            react_1.default.createElement("table", { className: "tooltip-table js-tooltip-table" },
                react_1.default.createElement("tbody", null, tooltipContent === null || tooltipContent === void 0 ? void 0 : tooltipContent.bars.map(function (bar) { return (react_1.default.createElement("tr", { key: bar.id },
                    react_1.default.createElement("td", null,
                        react_1.default.createElement(react_1.default.Fragment, null, 
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        t(bar.name))),
                    react_1.default.createElement("td", { className: "tooltip-percentage" }, bar.id !== 'totalDecisions' && bar.id !== 'received' && react_1.default.createElement("span", null, (0, number_1.percentage)(bar.percentage, 1))),
                    react_1.default.createElement("td", { className: "color-".concat(bar.id) }, bar.value))); }))))));
}
exports.Tooltip = Tooltip;
