"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.computeHighestBinBarHeight = exports.showTooltip = exports.computeTooltipContent = exports.getBar = exports.getTooltipTitleDate = void 0;
var d3_selection_1 = require("d3-selection");
var date_fns_1 = require("date-fns");
var graphUtil_1 = require("../../graphUtil");
var getTooltipTitleDate = function (graphData, hoveredBarIndex, aggregationInterval) {
    return (0, date_fns_1.format)(new Date(graphData[hoveredBarIndex].date || 0), graphUtil_1.DATE_FORMATS[aggregationInterval]);
};
exports.getTooltipTitleDate = getTooltipTitleDate;
var getBar = function (value, dataKey, curBar, graphData, resources) {
    var percentage = 0;
    if (dataKey !== 'totalDecisions' && dataKey !== 'received') {
        percentage = value / graphData[curBar].totalDecisions;
    }
    return { id: dataKey, name: resources[dataKey], value: value, percentage: percentage };
};
exports.getBar = getBar;
var computeTooltipContent = function (hoveredBarIndex, graphData, dataWithDate, series, resources, aggregationInterval) {
    var bars = [];
    var hasValueBiggerThanZero = false;
    var rects = series.selectAll('rect').filter(function (_, i) {
        return i === hoveredBarIndex;
    });
    rects.each(function (d) {
        var value = d.data[d.key];
        if (value > 0)
            hasValueBiggerThanZero = true;
        bars.push((0, exports.getBar)(value, d.key, hoveredBarIndex, graphData, resources));
    });
    if (hasValueBiggerThanZero) {
        return {
            bars: bars,
            title: (0, exports.getTooltipTitleDate)(dataWithDate, hoveredBarIndex, aggregationInterval),
        };
    }
    return null;
};
exports.computeTooltipContent = computeTooltipContent;
var showTooltip = function (target, event, x, series, widthPerStack, lastHoveredBarIndex, setLastHoveredBarIndex, setIsTooltipOpen, setPosAndContent) {
    var _a = __read((0, d3_selection_1.pointer)(event, target), 1), mouseX = _a[0];
    var initialX = x(0) || 0;
    var hoveredBarIndex = Math.floor((mouseX - initialX) / widthPerStack);
    if (hoveredBarIndex === lastHoveredBarIndex) {
        return;
    }
    setLastHoveredBarIndex(hoveredBarIndex);
    // Highlight the hovered bar
    series.selectAll('rect').classed('highlighted', function (_, i) { return i === hoveredBarIndex; });
    setIsTooltipOpen(true);
    setPosAndContent(hoveredBarIndex);
};
exports.showTooltip = showTooltip;
var computeHighestBinBarHeight = function (y, lastHoveredBarIndex, graphData, dataToShow, isChartModeStacked) {
    var maxNum = 0;
    var data = graphData[lastHoveredBarIndex];
    if (isChartModeStacked) {
        // if it is stacked mode, calc the stack height
        if (dataToShow.totalDecisions && !!data) {
            maxNum = data.totalDecisions;
        }
        else {
            graphUtil_1.legendKeys.forEach(function (key) {
                if (dataToShow[key] && !!data) {
                    maxNum += data[key];
                }
            });
        }
    }
    else {
        // if it is grouped mode, calc the heightest bar
        var arr_1 = [];
        graphUtil_1.legendKeys.forEach(function (key) {
            if (dataToShow[key] && !!data) {
                arr_1.push(data[key]);
            }
        });
        maxNum = Math.max.apply(Math, __spreadArray([], __read(arr_1), false));
    }
    return y(maxNum);
};
exports.computeHighestBinBarHeight = computeHighestBinBarHeight;
