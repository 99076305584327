(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('AdminFilterCreateCtrl', AdminFilterCreateCtrl);


  function AdminFilterCreateCtrl(ApiFilters, $stateParams, $state, Notifier, tFilter) {
    const vm = this;

    // set default values of a newly created rule
    vm.filter = {
      enabled: true,
      result: 'NOTHING',
      rule: ''
    };
    vm.domain = $stateParams.domain;
    vm.create = create;
    vm.afterCreate = afterCreate;
    vm.cancel = cancel;

    activate();

    function afterCreate() {
      Notifier.display(tFilter('AUTOMATION.DECISION_RULES.RULE_BUILDER.SUCCESS_CREATE_TEXT', {filterName: vm.filter.name}, 'messageformat'), {type: 'success', ttl: 1800});

      $state.go('app.assignment.automation.filters', {domain: vm.domain});
    }

    function cancel() {
      $state.go('app.assignment.automation.filters', {domain: vm.domain});
    }

    function whenErrorSaving(err) {
      if (err.status === 401) {
        return;
      }
      Notifier.display(tFilter('AUTOMATION.DECISION_RULES.ERROR.SAVE_ERROR'), {type: 'failure', ttl: 6000}, err);
      console.error(err);
    }

    function create(filterForm) {
      ApiFilters.create(vm.filter, vm.domain).then(function() {
        afterCreate(filterForm);
      }).catch(whenErrorSaving);
    }

    function activate() {

    }
  }
})();
